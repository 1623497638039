import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { SituationResolver } from '@core/resolvers';
import { AppRouteUrls } from '@core/routing/app/route-urls';
import {
  AdventureService,
  ApplicationContext,
  PopupApiService,
  SituationContext,
  WaypointService
} from '@core/services';
import {
  DEPRECATED_POPUP_ORIGIN_QUERY_STRING,
  LANG_QUERY_STRING
} from '@models/adventure/adventure-query-string';
import { ApplicationName } from '@models/context/application-name';
import { ErrorCodes } from '@models/error-codes/error-codes';
import { WaypointType } from '@models/waypoint/waypoint-type';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PopupGuard {
  constructor(
    private adventureService: AdventureService,
    private context: ApplicationContext,
    private popupApiService: PopupApiService,
    private router: Router,
    private situationResolver: SituationResolver,
    private situationContext: SituationContext,
    private translateService: TranslateService,
    private waypointService: WaypointService
  ) {}

  public canActivate(next: ActivatedRouteSnapshot): Observable<UrlTree> | boolean | UrlTree {
    if (this.context.applicationName !== ApplicationName.Popup) return true;

    const situation = this.situationResolver.resolve(next);

    const lang: string =
      next.queryParams[LANG_QUERY_STRING] || this.translateService.getBrowserLang();

    const originId: string = next.queryParams[DEPRECATED_POPUP_ORIGIN_QUERY_STRING];

    if (!originId || !situation)
      return this.router.parseUrl(`/${AppRouteUrls.ERROR(ErrorCodes.ERROR_404)}`);

    this.situationContext.saveToSituationContext(situation);
    this.popupApiService.handleUserId(next);

    return combineLatest({
      lang: this.translateService.use(lang),
      instanceUrl: this.waypointService
        .getWaypoint(originId, this.situationContext.situation.gender)
        .pipe(
          map(origin => {
            if (!origin || origin.type !== WaypointType.diploma)
              throw new Error('The origin was not found or is not a diploma.');

            return origin;
          }),
          switchMap(origin => this.adventureService.createAdventure({ origin })),
          map(instance => this.router.parseUrl(`/${AppRouteUrls.ADVENTURE_MAP(instance.id)}`)),
          catchError(() => of(this.router.parseUrl(`/${AppRouteUrls.ERROR(ErrorCodes.ERROR_404)}`)))
        )
    }).pipe(map(({ instanceUrl }) => instanceUrl));
  }
}
