import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable, of, take } from 'rxjs';
import { selectIsExpeditionAvailable } from './settings.selectors';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class FeatureSettingsGuard {
  constructor(private router: Router, private store: Store) {}

  public canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | boolean | UrlTree {
    const activePath = next.routeConfig.path;

    //  TODO implements others features
    if (activePath === 'expeditions') {
      let isExpedition: boolean;

      this.store
        .select(selectIsExpeditionAvailable)
        .pipe(take(1), untilDestroyed(this))
        .subscribe(isAvailable => {
          isExpedition = isAvailable;
        });

      if (!isExpedition) {
        return this.router.parseUrl('/');
      }

      return isExpedition;
    }

    // TODO notifications ?

    return of(false);
  }
}
