import { Injectable, OnDestroy } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig
} from '@angular/material/legacy-dialog';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subject, takeUntil } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DialogService implements OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(private dialog: MatDialog, private router: Router) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.dialog.closeAll());
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Build the default dialog configuration
   *
   * @param data The dialog data
   * @param width An additional class for the dialog panel
   * @returns The default dialog configuration
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public defaultDialogConfig(data?: any, additionalClass: string = ''): MatDialogConfig {
    return this.dialogConfig('default-dialog', data, additionalClass);
  }

  /**
   * Build the simple dialog configuration
   *
   * @param data The dialog data
   * @param width An additional class for the dialog panel
   * @returns The simple dialog configuration
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public simpleDialogConfig(data?: any, additionalClass: string = ''): MatDialogConfig {
    return this.dialogConfig('simple-dialog', data, additionalClass);
  }

  /**
   * Build a dialog configuration
   *
   * @param panelClass The main panel class
   * @param data The dialog data
   * @param additionalClass An additional class for the dialog panel
   * @returns The dialog configuration
   */
  private dialogConfig(
    panelClass: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any,
    additionalClass?: string
  ): MatDialogConfig {
    return {
      autoFocus: false,
      panelClass: additionalClass ? [panelClass, additionalClass] : [panelClass],
      data
    };
  }
}
