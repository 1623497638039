import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AppRouteUrls } from '@core/routing/app/route-urls';
import { ApplicationContext, SituationContext } from '@core/services';
import { Product } from '@models/context/product';

@Injectable({ providedIn: 'root' })
export class SituationRedirectionGuard {
  constructor(
    private context: ApplicationContext,
    private router: Router,
    private situationContext: SituationContext
  ) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const isNotConnectAndHaveNoSituation =
      !this.situationContext.situation && this.context.product !== Product.Connect;

    if (isNotConnectAndHaveNoSituation) {
      const queryParams = { redirectUrl: state.url, ...next.queryParams };

      return this.router.createUrlTree([`/${AppRouteUrls.SITUATION}`], {
        queryParams
      });
    }

    return true;
  }
}
