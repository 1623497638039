import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Expand,
  ExpandDiplomaType,
  ExpandDomain,
  ExpandTypeEnum,
  Waypoint
} from '@clients/adventure/bean/waypoint';
import { Nomenclature } from '@clients/poi/poi';
import { ApplicationContext } from '@core/services';
import { ApiMetricInList } from '@features/admin/models';
import { Adventure } from '@models/adventure/adventure';
import { AdventureType } from '@models/adventure-type/adventure-type';
import { Definition } from '@models/definition/definition';
import { Filters } from '@models/filters/filters';
import { ApiFilters } from '@models/filters/filters.api';
import { Instance } from '@models/instance/instance';
import { ApiInstance } from '@models/instance/instance.api';
import { ApiLeadRoadmap, LeadRoadmap } from '@models/lead/lead-roadmap';
import { HumanPoi } from '@models/poi/human-poi/human-poi';
import { ApiHumanPoi } from '@models/poi/human-poi/human-poi.api';
import { Situation } from '@models/situation/situation';
import { WaypointType } from '@models/waypoint/waypoint-type';
import { ExpandParams } from '@services/instance.service';
import { Dayjs } from 'dayjs';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class InstanceClient {
  private readonly INSTANCES_URI = `${environment.adventureUri}/instances`;

  constructor(private context: ApplicationContext, private http: HttpClient) {}

  /* -- INSTANCE -- */

  /**
   * List instances attached to the authenticated user for an application
   */
  public instances(): Observable<Instance[]> {
    return this.http
      .get<ApiInstance[]>(`${this.INSTANCES_URI}/list`)
      .pipe(map((is: ApiInstance[]) => is.map((i: ApiInstance) => Instance.build(i))));
  }

  public getInstance(id: string): Observable<Instance> {
    return this.http
      .get<ApiInstance>(`${this.INSTANCES_URI}/${id}`)
      .pipe(map((i: ApiInstance) => Instance.build(i)));
  }

  /**
   * Get the number of started instances for an application from a date
   *
   * @param applicationName The application name
   * @param startDate The date from when the count starts
   * @returns The number of started instances from the given date
   */
  public countStartedInstances(applicationName: string, startDate: Dayjs): Observable<number> {
    const params: HttpParams = new HttpParams()
      .set('a', applicationName)
      .set('d', `${startDate.unix()}`);

    return this.http.get<number>(`${this.INSTANCES_URI}/started`, { params });
  }

  /**
   * Get the number of completed instances for an application from a date
   *
   * @param applicationName The application name
   * @param startDate The date from when the count starts
   * @returns The number of completed instances from the given date
   */
  public countCompletedInstances(applicationName: string, startDate: Dayjs): Observable<number> {
    const params: HttpParams = new HttpParams()
      .set('a', applicationName)
      .set('d', `${startDate.unix()}`);

    return this.http.get<number>(`${this.INSTANCES_URI}/completed`, { params });
  }

  /**
   * Get the number of started instances for an application from a date
   *
   * @param applicationName The application name
   * @param startDate The date from when the count starts
   * @returns The number of started instances from the given date
   */
  public countOrigins(applicationName: string, startDate: Dayjs): Observable<ApiMetricInList[]> {
    const params: HttpParams = new HttpParams()
      .set('a', applicationName)
      .set('d', `${startDate.unix()}`);

    return this.http.get<ApiMetricInList[]>(`${this.INSTANCES_URI}/origins-count`, {
      params
    });
  }

  /**
   * Create an instance from waypoint as origin or destination (or both)
   * And from an application and a situation
   *
   * @returns The created instance
   */
  public createInstanceFromWaypoint(
    situation: Situation,
    adventure: Adventure,
    adventureType: AdventureType
  ): Observable<Instance> {
    const { origin, destination } = adventure;

    return this.http
      .post<ApiInstance>(`${this.INSTANCES_URI}/create`, {
        application: this.context.applicationName,
        situation: situation.toApi(),
        origin,
        destination,
        type: adventureType || (destination ? AdventureType.Job : AdventureType.Diploma)
      })
      .pipe(map((i: ApiInstance) => Instance.build(i)));
  }

  /**
   * Create an instance from a given instance containing a definition
   *
   * @param instance The instance gathering the user situation, the definition and the user email
   * @returns The created instance
   */
  public createInstanceFromInstance(instance: Instance): Observable<Instance> {
    return this.http
      .post<ApiInstance>(`${this.INSTANCES_URI}/initiate`, instance.toApi())
      .pipe(map((i: ApiInstance) => Instance.build(i)));
  }

  /**
   * Clone an instance
   *
   * @param instance The instance gathering the user situation, the definition and the user email
   * @returns The created instance
   */
  public cloneInstance(instance: Instance): Observable<Instance> {
    return this.http
      .post<ApiInstance>(`${this.INSTANCES_URI}/clone`, instance.toApi())
      .pipe(map((i: ApiInstance) => Instance.build(i)));
  }

  /**
   * Attach an instance to an email
   *
   * @param instanceId The instance Ids to attach to the email
   * @param email The email to which instance has to be attached. It is not needed (and ignored) if
   *              a user is authenticated
   */
  public attachInstance(instanceId: string, email: string): Observable<void> {
    let params = new HttpParams();

    if (email) params = params.set('email', email);

    return this.http.post<void>(`${this.INSTANCES_URI}/attached`, [instanceId], { params });
  }

  /**
   * Create a lead for a save roadmap action & send the roadmap by email to the user attached to the instance
   *
   * @param leadRoadmap The lead roadmap
   */
  public sendRoadmap(leadRoadmap: LeadRoadmap): Observable<LeadRoadmap> {
    return this.http
      .post<ApiLeadRoadmap>(`${this.INSTANCES_URI}/send-roadmap`, leadRoadmap.toApi())
      .pipe(map(lead => LeadRoadmap.build(lead)));
  }

  /* -- MAP -- */

  /**
   * Get the list of domains and waypoints for the next step in the adventure
   *
   * @param instanceId: id of the instance
   * @param expandParams: The params of the expand
   * @returns An observable emitting an expand of type Domain or DiplomaType
   */
  public expand(instanceId: string, expandParams: ExpandParams): Observable<Expand> {
    const { waypoints, stage, gender, filters, type, nomenclature } = expandParams;

    let params = new HttpParams().set('gender', gender);

    if (type) params = params.set('type', type.toString());

    if (nomenclature) params = params.set('nomenclature', nomenclature.toString());

    const path = waypoints[0] ? waypoints : null;

    if (filters.useDiplomaType) {
      return this.http
        .patch<ExpandDiplomaType>(
          `${this.INSTANCES_URI}/${instanceId}/expand/${stage}`,
          { path, filters: filters?.toApi() },
          { params }
        )
        .pipe(map(expand => ({ ...expand, type: ExpandTypeEnum.DiplomaType })));
    }

    return this.http
      .patch<ExpandDomain>(
        `${this.INSTANCES_URI}/${instanceId}/expand/${stage}`,
        { path, filters: filters?.toApi() },
        { params }
      )
      .pipe(map(expand => ({ ...expand, type: ExpandTypeEnum.Domain })));
  }

  /**
   * Get a list of waypoint to go after the current step
   *
   * @param id The id of the adventure
   * @param stage The stage of the instance
   * @param filters
   * @param type Type of the waypoint (diploma or job)
   * @returns an Expand
   */
  public expandBis(
    id: string,
    stage: number,
    filters: unknown,
    type: WaypointType,
    nomenclature: WaypointType
  ): Observable<Expand> {
    let params = new HttpParams();

    if (type) params = params.set('type', type.toString());

    if (nomenclature) params = params.set('nomenclature', nomenclature.toString());

    return this.http.patch<Expand>(
      `${this.INSTANCES_URI}/${id}/expand/${stage}`,
      { path: null, filters },
      { params }
    );
  }

  /**
   * Get the expand without instanceId for Internship
   *
   * @param waypoints The waypoint list. With one waypoint (the origin)
   * @param partialDefinition The partial definition (empty name and application)
   * @returns An observable emitting an expand, listing the next diplomas and/or jobs
   */
  public expandWithoutInstanceIdForInternship(
    waypoints: Waypoint[],
    partialDefinition: Partial<Definition>
  ): Observable<Expand> {
    return this.http.post<Expand>(`${this.INSTANCES_URI}/expand/internship`, {
      definition: partialDefinition,
      waypoints
    });
  }

  /* -- HUMAN -- */

  // FIXME: handle by adventure service for now but need to be replace to poi service (create a jar)
  /**
   * Get Humans POIs for an instance
   *
   * @param id The instance ID
   * @param nomenclature The Human POIs nomenclature. Can be either alumni or student
   * @param stage The instance stage
   * @param waypoints The instance waypoints
   * @returns An array of HumanPoi objects built from the array of HumanPoiResponse received from
   *          Adventure Service
   */
  public humanPois(
    id: string,
    nomenclature: Nomenclature,
    stage?: number,
    waypoints?: Waypoint[],
    humansFilters?: Filters
  ): Observable<HumanPoi[]> {
    let params = new HttpParams();

    if (stage != null && stage >= 0) params = params.set('stage', String(stage));

    let filters: ApiFilters = {
      withWaypoints: true,
      isGenderFromUser: false
    };

    if (humansFilters) {
      filters = { ...humansFilters.toApi(), ...filters };
    }

    return this.http
      .post<ApiHumanPoi[]>(
        `${this.INSTANCES_URI}/${id}/${nomenclature}`,
        { waypoints, filters },
        { params }
      )
      .pipe(
        map((apiHumanPois: ApiHumanPoi[]) => {
          if (!apiHumanPois) return [];

          return apiHumanPois.map((apiHumanPoi: ApiHumanPoi) => HumanPoi.build(apiHumanPoi));
        }),
        share()
      );
  }

  /**
   * Update an instance
   *
   * @param instance The instance to save
   * @returns The updated instance
   */
  public updateInstance(instance: Instance): Observable<Instance> {
    return this.http
      .post<ApiInstance>(`${this.INSTANCES_URI}`, instance.toApi())
      .pipe(map(apiInstance => Instance.build(apiInstance)));
  }
}
