import { Status } from '@clients/adventure/bean/situation';
import { Step } from '@clients/poi/poi';
import { ApplicationName } from '@models/context/application-name';
import { hroadsGenderToGender } from '@models/gender/gender';
import { Group } from '@models/group/group';
import { IdentityClaims } from '@models/identity-claims/identity-claims';
import { Situation } from '@models/situation/situation';
import { hroadsTypeToWaypointType } from '@models/waypoint/waypoint-type';
import { Role } from '../role/role';
import { ApiHroadsUser } from './hroads-user.api';
import { ApiUser } from './user.api';
import { SessionStorageUser } from './user.session-storage';

export interface IPerson {
  firstName: string;
  lastName: string;
}

/**
 * A user
 */
export class User implements IPerson {
  /**
   * The user situation
   */
  public situation?: Situation;

  /**
   * Whether the user has all rights
   */
  public get hasFullRights(): boolean {
    return this.roles.includes(Role.SuperAdmin) || this.roles.includes(Role.ProjectManager);
  }

  /**
   * Whether the user is a school having no application
   */
  public get isSchool(): boolean {
    return (
      this.groups.includes(Group.MyRoadBasic) &&
      !this.groups.includes(Group.Acquisition) &&
      !!this.managedSchoolId
    );
  }

  /**
   * Whether the user manages an Acquisition application
   */
  public get manageAnAcquisitionApp(): boolean {
    return this.groups.includes(Group.Acquisition) && !!this.managedApplicationName;
  }

  /**
   * A user
   *
   * @param uuid The user uuid
   * @param email The user email
   * @param groups The user and applications groups. It is empty if the user and applications has no affected role (i.e. end user)
   * @param roles The user roles. It is empty if the user has no affected role (i.e. end user)
   * @param firstName The user first name
   * @param lastName The user last name
   * @param managedApplicationName The name of the application (Acquisition) that the user manages. It
   *    is empty if the user roles are not related to a specific application
   * @param managedSchoolId The UUID of the school (location) that the user manages. It is empty if
   *    the user roles are not related to a specific school
   */
  constructor(
    public uuid: string,
    public email: string,
    public firstName: string,
    public lastName: string,
    public groups: Group[],
    public roles: Role[],
    public managedApplicationName: string,
    public managedSchoolId: string
  ) {}

  /**
   * Build the User object from identity claims from the identity provider
   *
   * @param claims The identity claims from the identity provider
   * @returns The new User object
   */
  public static fromIdentityClaims(claims: IdentityClaims): User {
    if (!claims || !claims.email) return null;

    return new User(
      claims.uuid,
      claims.email,
      claims.firstName || '',
      claims.lastName || '',
      (claims.groups as Group[]) || [],
      (claims.roles as Role[]) || [],
      claims.app || '',
      claims.loc || ''
    );
  }

  /**
   * Build the User object from the Api User object
   *
   * @param apiUser The Api User object
   * @returns The new User object
   */
  public static build(apiUser: ApiUser): User {
    return new User(
      apiUser.uuid,
      apiUser.email,
      apiUser.firstname || '',
      apiUser.lastname || '',
      [],
      [],
      '',
      ''
    );
  }

  /**
   * Build a User object from the SessionStorageUser object
   *
   * @param sessionStorageUser The SessionStorageUser object
   * @returns The new User object
   */
  public static buildFromSessionStorage(sessionStorageUser: SessionStorageUser): User {
    return new User(
      sessionStorageUser.uuid,
      sessionStorageUser.email || '',
      sessionStorageUser.firstName || '',
      sessionStorageUser.lastName || '',
      [],
      [],
      '',
      ''
    );
  }

  /**
   * Whether the user has access to a route
   *
   * @param applicationName The name of the current application
   * @param routeRoles The roles authorized by the route
   * @param routeGroups The groups authorized by the route
   */
  public hasAccess(applicationName: string, routeRoles: Role[], routeGroups: Group[]): boolean {
    // A user that has all rights always has access
    if (this.hasFullRights) return true;

    // The user has one of the authorized roles
    if (this.hasAuthorizedRole(routeRoles)) {
      /**
       * If the user tries to access to My Road and the route is restricted to MyRoadBasic group, it
       * only has access if it is a school
       */
      if (applicationName === ApplicationName.MyRoad && routeGroups.includes(Group.MyRoadBasic))
        return this.isSchool;

      /**
       * If the user tries to access to an application and the route is restricted to Acquisition
       * group, it only has access if it manages the application
       */
      if (applicationName && routeGroups.includes(Group.Acquisition))
        return this.manageAcquisitionApp(applicationName);

      // Otherwise, it only have access if it has one of the authorized groups
      return this.hasAuthorizedGroup(routeGroups);
    }

    // If the user has not the authorized role, it can not have access
    return false;
  }

  /**
   * Whether the user manages the given application
   * It does not check if it has access to manage it
   *
   * @param applicationName The name of an Acquisition application
   */
  public manageAcquisitionApp(applicationName: string): boolean {
    return this.manageAnAcquisitionApp && this.managedApplicationName === applicationName;
  }

  /**
   * Whether the user has one of the authorized roles
   *
   * @param authorizedRoles The authorized roles
   */
  public hasAuthorizedRole(authorizedRoles: Role[]): boolean {
    if (!authorizedRoles.length) return true;

    if (this.hasFullRights) return true;

    return this.roles.reduce(
      (hasAuthorizedRole, userRole) => hasAuthorizedRole || authorizedRoles.includes(userRole),
      false
    );
  }

  /**
   * Whether the user has one of the authorized groups
   *
   * @param authorizedGroups The authorized groups
   */
  public hasAuthorizedGroup(authorizedGroups: Group[]): boolean {
    if (!authorizedGroups.length) return true;

    if (this.hasFullRights) return true;

    return this.groups.reduce(
      (hasAuthorizedGroup, userGroup) => hasAuthorizedGroup || authorizedGroups.includes(userGroup),
      false
    );
  }

  /**
   * Get the Api User object from the User object
   *
   * @returns The corresponding Api User object
   */
  public toApi(): ApiUser {
    return {
      email: this.email,
      firstname: this.firstName,
      lastname: this.lastName
    } as ApiUser;
  }

  /**
   * Get the Api User object from the User object
   *
   * @returns The corresponding Api User object
   */
  public toUserSessionStorage(): SessionStorageUser {
    return {
      uuid: this.uuid,
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName
    } as SessionStorageUser;
  }

  /**
   * Add additional information from the Hroads API to the user
   *
   * @param hroadsUser The user from the Hroads API
   */
  public mergeWithHroadsUser(hroadsUser: ApiHroadsUser): void {
    this.situation = new Situation(
      undefined,
      hroadsGenderToGender(hroadsUser.gender),
      Status.student // FIXME
    );

    if (hroadsUser.app) {

      const { offer, node } = hroadsUser.app;

      this.situation.applicationStep = {
        name: offer.label,
        isInternship: offer.is_internship,
        type: hroadsTypeToWaypointType(node.type),
        waypoint: {
          id: node.id,
          type: node.type,
          label: node.name  // TODO: Fix the backend, bad property name ('name' instead of 'label')
        },
        offer,
        location: {
          name: offer.campuses[0].name,
          city: offer.campuses[0].city
        },
        resumeId: hroadsUser.app.id
      } as Step;
    }

    if (hroadsUser.current) {
      this.situation.currentStep = {
        name: hroadsUser.current.name,
        type: hroadsTypeToWaypointType(hroadsUser.current.node.type),
        waypoint: {
          id: hroadsUser.current.node.id,
          type: hroadsUser.current.node.type,
          label: hroadsUser.current.name  // TODO: Fix the backend, bad property name ('name' instead of 'label')
        }
      } as Step;
    }
  }
}
