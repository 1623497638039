import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class ErrorGuard {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  public canActivate(next: ActivatedRouteSnapshot): boolean | UrlTree {
    if (next.queryParams.dialog === 'true') {
      this.document.defaultView.history.back();

      return false;
    }

    return true;
  }
}
